<template>
  <table class="compare-table table table_default">
    <template v-if="config.isComplex">
      <tr>
        <th colspan="2">{{ $t('result.result') }}</th>
      </tr>
      <tr>
        <td v-for="(result, i) in comparingResults" :key="i">
          <template v-if="!result[idKey]">--</template>
          <template v-else>
            <div
              :is="result.timeOffset && result.timeOffset !== '00:00' ? 'TimeOffset' : 'div'"
              :offset="result.timeOffset"
            >
              <span>
                {{ result.resultByCalcMethod || '--' }}
              </span>
              <div
                class="text-xs color-muted mt-4"
                v-if="result.delayByCalcMethod"
              >
                +{{ result.delayByCalcMethod }}
              </div>
            </div>
          </template>
        </td>
      </tr>
    </template>
    <template v-if="!config.isComplex && !config.hideIndividualResult">
      <tr>
        <th colspan="2">{{ $t('result.result') }} (chip)</th>
      </tr>
      <tr>
        <td v-for="(result, i) in comparingResults" :key="i">
          <template v-if="!result[idKey]">--</template>
          <template v-else>
            {{ result.individualResult || '--' }}
            <div
              class="text-xs color-muted mt-4"
              v-if="result.individualDelay"
            >
              +{{ result.individualDelay }}
            </div>
          </template>
        </td>
      </tr>
    </template>
    <template v-if="!config.isComplex && !config.hideAbsoluteResult">
      <tr>
        <th colspan="2">{{ $t('result.result') }} (gun)</th>
      </tr>
      <tr>
        <td v-for="(result, i) in comparingResults" :key="i">
          <template v-if="!result[idKey]">--</template>
          <template v-else>
            {{ result.absoluteResult || '--' }}
            <div
              class="text-xs color-muted mt-4"
              v-if="result.absoluteDelay"
            >
              +{{ result.absoluteDelay }}
            </div>
          </template>
        </td>
      </tr>
    </template>
    <tr>
      <th colspan="2">{{ $t('result.position') }} ({{ $t('result.position_abs') }})</th>
    </tr>
    <tr>
      <td v-for="(result, i) in comparingResults" :key="i">
        {{ getPositionText(result.position, result.status) || '--' }}
      </td>
    </tr>
    <!-- Stages : BEGIN -->
    <template v-if="config.isComplex">
      <template v-for="(stage, stageIndex) in stages">
        <template v-if="!showCheckpoints || !stage.stagesInfo.length">
          <tr :key="'_' + stageIndex">
            <th colspan="2">{{ stage.name }}</th>
          </tr>
          <tr :key="stageIndex">
            <td v-for="(result, i) in comparingResults" :key="i">
              <template v-if="result[stagesKey]">
                {{ result[stagesKey][stageIndex].resultByCalcMethod || '--'}}
                <div
                  class="text-xs color-muted mt-4"
                  v-if="result[stagesKey][stageIndex].delayByCalcMethod"
                >
                  +{{ result[stagesKey][stageIndex].delayByCalcMethod }}
                </div>
              </template>
              <template v-else>--</template>
            </td>
          </tr>
        </template>
        <template
          v-else
          v-for="(checkpoint, checkpointIndex) in stage.stagesInfo"
        >
          <tr :key="'_' + stageIndex + '_' + checkpointIndex">
            <th colspan="2">
              <Icon :name="stage.disciplineCode" class="color-muted" size="xs"></Icon>
              {{ checkpoint.name }}
            </th>
          </tr>
          <tr :key="stageIndex + '_' + checkpointIndex">
            <td v-for="(result, i) in comparingResults" :key="i">
              <template v-if="result[stagesKey]">
                {{ result[stagesKey][stageIndex][stagesKey][checkpointIndex].resultByCalcMethod || '--'  }}
                <div
                  class="text-xs color-muted mt-4"
                  v-if="result[stagesKey][stageIndex][stagesKey][checkpointIndex].delayByCalcMethod"
                >
                  +{{ result[stagesKey][stageIndex][stagesKey][checkpointIndex].delayByCalcMethod }}
                </div>
              </template>
              <template v-else>--</template>
            </td>
          </tr>
        </template>
      </template>
    </template>
    <template v-else-if="showCheckpoints">
      <template v-for="(stage, stageIndex) in stages">
        <tr :key="'_' + stageIndex">
          <th colspan="2">{{ stage.name }}</th>
        </tr>
        <tr :key="stageIndex">
          <td v-for="(result, i) in comparingResults" :key="i">
            {{ (result[stagesKey] && result[stagesKey][stageIndex].resultByCalcMethod) || '--' }}
            <div
              class="text-xs color-muted mt-4"
              v-if="result[stagesKey] && result[stagesKey][stageIndex].delayByCalcMethod"
            >
              +{{ result[stagesKey][stageIndex].delayByCalcMethod }}
            </div>
          </td>
        </tr>
      </template>
    </template>
    <!-- Stages : END -->
    <tr>
      <th colspan="2">{{ $t('result.category') }}</th>
    </tr>
    <tr>
      <td v-for="(result, i) in comparingResults" :key="i">
        {{ result.genderAgeNominationName || '--' }}
      </td>
    </tr>
  </table>
</template>

<script>
import { ResultStatus } from '@/enums';
import { calculateTime } from '@/assets/js/helpers';
import TimeOffset from '@/components/TimeOffset/TimeOffset.vue';

export default {
  name: 'CompareTable',
  components: {
    TimeOffset,
  },
  props: {
    config: Object,
    stages: Array,
    results: Array,
    indexes: Array,
    showCheckpoints: Boolean,
  },
  data() {
    return {
      ReversedResultStatus: this.$reversedEnum(ResultStatus),
    };
  },
  computed: {
    comparingResults() {
      return this.indexes
        .map((index) => (this.results[index] ? this.results[index] : {}));
    },
    stagesKey() {
      return this.config.isRelay ? 'stagesResults' : 'stageResults';
    },
    idKey() {
      return this.config.isRelay ? 'teamId' : 'participantId';
    },
  },
  methods: {
    calculateTime,
    getPositionText(position, status) {
      if (this.ReversedResultStatus[status] === 'Finish') {
        return position;
      }
      return this.ReversedResultStatus[status] || position;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./CompareTable";
</style>
